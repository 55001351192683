import { Suspense, lazy } from 'react';
import { CompareSelection } from 'src/components/compareSelection/CompareSelection';
import { useRef, useState } from 'react';
import { useAddEmailSubscription, useBanks, useMortgageData, usePublishApplyInfo } from './CompareApi';
import { Text, Container, Title, Box, Divider, Button, Stack } from '@mantine/core';
import { Hero } from 'src/components/hero/Hero';
import { CompareResult } from 'src/components/compareResult/CompareResult';
import { notifications } from '@mantine/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import { CompareRequestEncoderDecoder } from './CompareRequestEncoderDecoder';
import { initializeRequest } from './initializeRequest';
import { requestTypes } from './types';
import { discounts } from 'src/components/compareSelection/types';
import Cookies from 'js-cookie';
import classes from './Compare.module.css';

const FAQ = lazy(() => import('src/components/faq/Faq'));
const About = lazy(() => import('src/components/about/About'));
const COOKIE_NAME = 'compareRequest';

export const Compare = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Initialize request and discounts state
  const { initialRequest, initialDiscountsState } = initializeRequest(
    COOKIE_NAME,
    new URLSearchParams(window.location.search).get('content'),
    requestTypes,
    discounts,
    {
      propertyValuation: 5000000,
      loanAmount: 2600000,
      propertyType: 'house',
      energyClass: 'Unknown',
      interestTerm: 'ThreeMonth',
      requestType: requestTypes[0]?.value,
    }
  );

  // State initializations
  const [request, setRequest] = useState(initialRequest);
  const [discountsState, setDiscountsState] = useState(initialDiscountsState);
  const [selectedTerm, setSelectedTerm] = useState(initialRequest.interestTerm);
  const [propertyValuation, setPropertyValuation] = useState<number>(initialRequest.propertyValuation);
  const [loanAmount, setLoanAmount] = useState<number>(initialRequest.loanAmount);
  const [selectedProperty, setSelectedProperty] = useState<string>(initialRequest.propertyType);
  const [selectedEnergyClass, setSelectedEnergyClass] = useState(initialRequest.energyClass);
  const debounceTimeout = useRef<number | null>(null);

  const { data: mortgages } = useMortgageData(request, !!request);
  const { data: banks } = useBanks();
  const emailSubscriptionMutation = useAddEmailSubscription();
  const publishApplyButtonMutataion = usePublishApplyInfo();

  const saveRequestToCookie = (updatedRequest: CompareRequest) => {
    const encodedRequest = CompareRequestEncoderDecoder.encodeToBase64(updatedRequest);
    Cookies.set(COOKIE_NAME, encodedRequest, { expires: 365 }); // Save for 1 year
  };

  const handleCompareSelection = (
    propertyValuation: number,
    loanAmount: number,
    energyClass: string,
    propertyType: string,
    discount: DiscountRequest[]
  ) => {
    const compareRequest = createCompareRequest(
      propertyValuation,
      loanAmount,
      selectedTerm,
      propertyType,
      energyClass,
      discount
    );
  
    if (compareRequest) handleMortgagesCompare(compareRequest);
  };

  const handleMortgagesCompare = (newRequest: CompareRequest) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = window.setTimeout(() => {
      setRequest(newRequest);
      console.log("req", newRequest)
      saveRequestToCookie(newRequest);
    }, 500);
  };

  const handleEmailSubscription = (email: string, token: string) => {
    if(!request) return;

    const encodedBase64 = CompareRequestEncoderDecoder.encodeToBase64(request);
    
    const subscribeRequest: EmailSubscriptionRequest = {
      email,
      content: encodedBase64,
      captchaToken: token,
    };
    
    emailSubscriptionMutation.mutate(subscribeRequest, {
      onSuccess: () => {
        notifications.show({
          title: 'Räntebevakning',
          message: 'Din bevakning är nu sparad!',
          color: 'green',
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 5000,
          theme: { colorScheme: 'dark' },
          position: "top-center",
        });
      },
      onError: () => {
        notifications.show({
          title: 'Räntebevakning',
          message: 'Din bevakning gick inte att spara!',
          color: 'red',
          icon: <FontAwesomeIcon icon={faCircleExclamation} />,
          autoClose: 5000,
          theme: { colorScheme: 'dark' },
          position: "top-center",
        });
      },
    });
  }

  const createCompareRequest = (
    propertyValuation: number,
    loanAmount: number,
    interestTerm: string,
    propertyType: string,
    energyClass: string,
    discounts: DiscountRequest[]
  ): CompareRequest | null => {
    if (propertyValuation <= 0 || loanAmount <= 0) {
      console.error("Invalid property valuation or loan amount");
      return null;
    }
  
    return {
      propertyValuation,
      loanAmount,
      propertyType,
      energyClass,
      interestTerm,
      requestType: requestTypes[1]!.value,
      bankSpecificDiscounts: discounts,
    };
  };

  const handleCompare = (interestTerm: string) => {
    const compareRequest = createCompareRequest(
      propertyValuation,
      loanAmount,
      interestTerm,
      selectedProperty,
      selectedEnergyClass,
      discountsState
    );
  
    if (compareRequest) {
      handleMortgagesCompare(compareRequest);
    }
  };

  const handleApplyButtonClick = (bankName: string, bankUrl: string) => {
    if(!request) return;
  
    const publishApplyInfoRequest: PublishApplyInfoRequest = {
      bankName: bankName,
      bankUrl: bankUrl,
      event: "mortgage-apply",
    };

    publishApplyButtonMutataion.mutate(publishApplyInfoRequest);
  };

  return (
    <>
      <Hero />
      <Container size="1200px" className={classes.gridContainer}>
        
        {/* Top section */}
        <Box className={classes.top} >
          <Title  order={3} className={classes.title}>
            Dina <span style={{ textDecoration: 'underline' }}>personliga</span> bolåneräntor
          </Title>
          <Text size="20px">
          Räntan du ser här är den du kan få hos bolånegivaren, baserat på din information och deras nuvarande listränta. Den slutliga räntan fastställs på utbetalningsdagen.
          </Text>
        </Box>

        {/* Left section (CompareResult) */}
        <Box className={classes.result}>
          <CompareResult
            handleCompare={handleCompare}
            selectedTerm={selectedTerm}
            setSelectedTerm={setSelectedTerm}
            banks={banks}
            mortgages={mortgages}
            handleEmailSubscription={handleEmailSubscription}
            handleApplyButtonClick={handleApplyButtonClick}
          />
        </Box>

        {/* Right section (CompareSelection) */}
        <Box className={classes.selection}>
          <CompareSelection
            discountsState={discountsState}
            setDiscountsState={setDiscountsState}
            propertyValuation={propertyValuation}
            setPropertyValuation={setPropertyValuation}
            loanAmount={loanAmount}
            setLoanAmount={setLoanAmount}
            selectedEnergyClass={selectedEnergyClass}
            setSelectedEnergyClass={setSelectedEnergyClass}
            selectedProperty={selectedProperty}
            setSelectedProperty={setSelectedProperty}
            handleCompareSelection={handleCompareSelection}
          />
        </Box>
      </Container>
      
      <Divider mt="60px" mb="60px" className={classes.hr}/>
       
      <section id="about" aria-labelledby="about-title" ref={ref}>
        <Container size="md" mb="60px">
        {inView && (
          <Suspense fallback={<div>Loading About...</div>}>
            <About />
          </Suspense>
        )}
        </Container>
      </section>

      {/* FAQ Section */}
      <section id="faq" aria-labelledby="faq-title" ref={ref}>
        <Container size="100%" pt="60px" pb="60px" className={classes.faqContainer}>
        {inView && (
          <Suspense fallback={<div>Loading FAQ...</div>}>
            <FAQ />
          </Suspense>
        )}
          <Stack justify="center" align="center" pt="60px">
            <Title order={3}> Har du fortfarande frågor?</Title>
            <Title order={4} mt="-20px" c="dimmed">Kontakta oss så hjälper vi dig gärna</Title>
            <Button
              component="a"
              mt="32px"
              href="mailto:hej@seranta.se?"
              target="_blank" // Optional: Opens in a new tab
              variant="filled"
              w="200px"
              size="lg"
              color="dark"
              radius={30}
            >
              Kontakta oss
            </Button>
          </Stack>
        </Container>
      </section>
    </>
  );
};