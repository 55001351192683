import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Button, Text } from '@mantine/core';
import classes from './BankCard.module.css'; // Adjust the import path as necessary

interface Props {
  handleApplyButtonClick: (bankName: string, bankUrl: string) => void;
  mortgage: {
    bankUrl: string;
    bankName: string;
  };
}

const MortgageApplyButton: React.FC<Props> = ({ handleApplyButtonClick, mortgage }) => {
  const handleClick = () => {
    handleApplyButtonClick(mortgage.bankName, mortgage.bankUrl)
  };

  return (
    <a
      href={mortgage.bankUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      <Button
        size="md"
        variant="filled"
        color="dark"
        radius="30px"
        className={classes.buttonApply}
        aria-label={`Ansök banklån hos ${mortgage.bankName}`}
      >
        <Text c="#fff" fw="500">
          Ansök här <FontAwesomeIcon className={classes.buttonApplyIcon} icon={faUpRightFromSquare} style={{ paddingLeft: '8px' }} />
        </Text>
      </Button>
    </a>
  );
};

export default MortgageApplyButton;