import { Card, Group, Stack, Switch, Text, Anchor, Button, Center, Box, NativeSelect } from '@mantine/core';
import { energyClasses, propertyTypes } from './types';
import { SliderValueBox } from '../sliderValueBox/SliderValueBox';
import { useState } from 'react';
import { Selection } from '../selection/Selection';
import classes from './CompareSelection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from "react";

interface CompareSelectionProps {
  discountsState: DiscountRequest[];
  setDiscountsState: React.Dispatch<React.SetStateAction<DiscountRequest[]>>;
  propertyValuation: number;
  setPropertyValuation: React.Dispatch<React.SetStateAction<number>>;
  loanAmount: number;
  setLoanAmount: React.Dispatch<React.SetStateAction<number>>;
  selectedEnergyClass: string;
  setSelectedEnergyClass: React.Dispatch<React.SetStateAction<string>>;
  selectedProperty: string;
  setSelectedProperty: React.Dispatch<React.SetStateAction<string>>;
  handleCompareSelection: (propertyValuation: number, loanAmount: number, energyClass: string, propertyType: string, discount: DiscountRequest[]) => void;
}

function debounce<T extends (...args: any[]) => void>(func: T, delay: number): T {
  let timeout: ReturnType<typeof setTimeout>;

  return ((...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  }) as T;
}

export function CompareSelection({
  discountsState,
  setDiscountsState,
  propertyValuation,
  setPropertyValuation,
  loanAmount,
  setLoanAmount,
  selectedEnergyClass,
  setSelectedEnergyClass,
  selectedProperty,
  setSelectedProperty,
  handleCompareSelection,
}: CompareSelectionProps) {
  const hasAnyDiscountApplied = discountsState.some((discount) => discount.isDiscountApplied);
  const [isOpen, setIsOpen] = useState(hasAnyDiscountApplied);
  
  const items = discountsState.map((item, index) => (
    <div key={item.bankId}>
    <Group justify="space-between" wrap="nowrap" key={item.title}>
      <div>
        <Text fw="500">{item.title}</Text>
        <Text size="16px" c="dimmed">
          {item.description}
        </Text>
      </div>
      <Switch 
        color="dark"
        onLabel="Ja" 
        offLabel="Nej" 
        size="xl" 
        checked={!!item.isDiscountApplied}
        onChange={() => handleSwitchChange(index)}
        aria-label={`Rabatt ${item.title}, ${item.isDiscountApplied ? 'aktiverad' : 'avaktiverad'}`}
      />
     </Group>
     {item.hasValue && item.isDiscountApplied && (
      <Box p="10px" mt="16px">
        <SliderValueBox
          value={item.value!}
          maxValue={8000}
          minValue={0}
          onValueChange={(value) => handleSliderChange(index, value)}
          step={100}
          disabled={!item.isDiscountApplied}
          label="Hur mycket handlar du för på ICA varje månad?"
      />
      </Box>
      )}
     </div>
  ));

  const handleSwitchChange = (index: number) => {
    const updatedItems = [...discountsState];
    
    if (updatedItems[index]) {
      if (updatedItems[index].hasValue && updatedItems[index].isDiscountApplied) {
        updatedItems[index].value = 0;
      }
  
      updatedItems[index].isDiscountApplied = !updatedItems[index].isDiscountApplied;
      
      if (!updatedItems[index].isDiscountApplied && updatedItems[index].hasValue) {
        updatedItems[index].value = 0;
      }
  
      setDiscountsState(updatedItems);
  
      handleCompareSelection(propertyValuation, loanAmount, selectedEnergyClass, selectedProperty, updatedItems);
    }
  };

  const debouncedHandleCompareSelection = useCallback(
    debounce((propertyValuation, loanAmount, selectedEnergyClass, selectedProperty, updatedItems) => {
      handleCompareSelection(propertyValuation, loanAmount, selectedEnergyClass, selectedProperty, updatedItems);
    }, 200), 
    []
  );

  const handleSliderChange = (index: number, value: number) => {
    setDiscountsState((prevState) => {
      const updatedItems = prevState.map((item, idx) =>
        idx === index ? { ...item, value } : item
      );
  
      // Trigger debounced comparison
      debouncedHandleCompareSelection(propertyValuation, loanAmount, selectedEnergyClass, selectedProperty, updatedItems);
  
      return updatedItems;
    });
  };

  const handlePropertyChange = (value: string) => {
    setSelectedProperty(value);
    handleCompareSelection(propertyValuation, loanAmount, selectedEnergyClass, value, discountsState);
  };

  const handlePropertyValuationChange = (value: number) => {
    const maxLoanAmount = 0.85 * value;
    if(loanAmount > maxLoanAmount){
      loanAmount = maxLoanAmount;
    }
    setPropertyValuation(value);
    setLoanAmount(loanAmount);
    handleCompareSelection(value, loanAmount, selectedEnergyClass, selectedProperty, discountsState);
  };

  const handleLoanAmountChange = (value: number) => {
    setLoanAmount(value);
    handleCompareSelection(propertyValuation, value, selectedEnergyClass, selectedProperty, discountsState);
  };

  const handleEnergyClassChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if(event.currentTarget.value === null){
      return;
    }
    setSelectedEnergyClass(event.currentTarget.value);
    handleCompareSelection(propertyValuation, loanAmount, event.currentTarget.value, selectedProperty, discountsState);
  };

  const toggleContent = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
    <Card withBorder radius="md" className={classes.card}>
      <Text size="20px">Justera värdena och se dina personliga bolåneräntor direkt</Text>
      <Selection 
          selectedValue={selectedProperty}
          onChange={handlePropertyChange}
          data={propertyTypes}
          size="lg"
          mt={8}
          ariaLabel='Bostadstyp'
          label='Bostadstyp'
          mtLabel={32}
        />
      <SliderValueBox
        value={propertyValuation}
        maxValue={15000000}
        minValue={200000}
        onValueChange={handlePropertyValuationChange}
        step={10000}
        label="Bostadens värde"
        mt={32}
      />
      <SliderValueBox
        maxValue={0.85 * propertyValuation}
        minValue={100000}
        onValueChange={handleLoanAmountChange}
        value={loanAmount}
        step={10000}
        label="Lånebelopp"
        mt={32}
      />
      <Text size="sm" mt="32px" c="dimmed">
        Belåningsgrad: {propertyValuation !== 0 ? ((loanAmount / propertyValuation) * 100).toFixed(0) : '0'} %
      </Text>
      <Text size="sm" mt="8px" c="dimmed">
        Kontantinsats: {(propertyValuation - loanAmount).toLocaleString('sv-SE')} kr
      </Text>
      <div
        style={{
          maxHeight: isOpen ? '650px' : '0',
          width: '100%',
          opacity: isOpen ? 1 : 0,
          overflow: 'hidden',
          transition: 'max-height 0.5s ease, opacity 0.5s ease',
        }}
      >
      <Stack gap="32px" mt="32px" >
        {items}
      </Stack>
      <Text mt="32px" fw="500">Bostadens energiklass <FontAwesomeIcon color='#48752C' icon={faLeaf} style={{paddingLeft: "4px"}}/></Text>
      <Text size="16px" c="dimmed">
        Du kan hitta energiklassinformation på &nbsp;
        <Anchor
          href="https://sokenergideklaration.boverket.se/search"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Länk till Boverkets energideklaration söksida"
        >
          Boverkets energideklaration söksida
        </Anchor>.
      </Text>
        <NativeSelect
          mt="8px"
          defaultValue={selectedEnergyClass}
          onChange={handleEnergyClassChange}
          data={energyClasses}
          aria-label="Välj fastighetens energiklass"
        />
      </div>
      <Center>
        <Button
          className={classes.toggleButton}
          onClick={toggleContent}
          variant="outline"
          size="md"
          radius="30px"
          color="dark"
          rightSection={ isOpen 
            ? <FontAwesomeIcon icon={faChevronUp} />
            : <FontAwesomeIcon icon={faChevronDown} />
          }
          pr={12}
          aria-label={isOpen ? "Dölj fler ränterabatter" : "Visa fler ränterabatter"}
        >
          Få extra rabatter
        </Button>
      </Center>
    </Card>
  </>
  );
}