import CookieConsent from 'react-cookie-consent';

export const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Acceptera"
      cookieName="cookieConsent"
      style={{ background: "#2B373B", color: "white", padding: "16px" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={365}
    >
      Vi använder cookies för att förbättra din upplevelse på vår webbplats. Genom att klicka på "Acceptera" godkänner du att vi använder cookies för att analysera webbplatsens trafik med hjälp av Google Analytics. Mer information finns i vår <a href="/cookie-policy" target="_blank">Cookiepolicy</a>.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
