import { NumberInput, Slider, rem, Text, StyleProp, MantineSpacing } from '@mantine/core';
import { useState, useEffect } from 'react';

interface SliderValueBoxProps {
  maxValue: number;
  minValue: number;
  value: number;
  step: number;
  onValueChange: (value: number) => void;
  disabled?: boolean;
  label: string;
  mt?: StyleProp<MantineSpacing> | undefined;
}

export function SliderValueBox({
  maxValue,
  minValue,
  value,
  onValueChange,
  disabled,
  step,
  label,
  mt
}: SliderValueBoxProps) {
  const formatValue = (val: number) => `${val.toLocaleString('sv-SE')} kr`;

  const [inputValue, setInputValue] = useState<number | "">(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleFinalChange = (val: number | null) => {
    const normalizedValue = val === null || isNaN(val) || val < minValue ? minValue : val;

    if (normalizedValue > maxValue) {
      onValueChange(maxValue);
    } else {
      onValueChange(normalizedValue);
    }
  };

  return (
    <>
      <Text id="slider-label" fw="500" mt={mt}>{label}</Text>
      <NumberInput
        mt="8px"
        value={inputValue}
        onChange={(val) => setInputValue(val === "" ? "" : Number(val))}
        onBlur={() => handleFinalChange(inputValue as number)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleFinalChange(inputValue as number);
            e.currentTarget.blur();
          }
        }}
        step={step}
        min={minValue}
        max={maxValue}
        hideControls
        disabled={disabled}
        suffix=" kr"
        allowNegative={false}
        allowDecimal={false}
        thousandSeparator=" "
        inputMode="numeric"
        aria-labelledby="slider-label"
      />
      <Slider
        color="dark"
        mt="16px"
        size="xs"
        thumbSize={24}
        value={value}
        step={step}
        min={minValue}
        max={maxValue}
        onChange={(val) => {
          setInputValue(val);
          onValueChange(val);
        }}
        disabled={disabled}
        label={(val) => formatValue(val)}
        styles={{ thumb: { borderWidth: rem(3), padding: rem(10) } }}
        aria-labelledby="slider-label"
      />
    </>
  );
}
