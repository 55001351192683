import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Compare } from 'src/pages/compare/Compare';
import { CookiePolicy } from 'src/pages/cookiePolicy/CookiePolicy';
import ErrorPage from 'src/pages/error/ErrorPage';
import Layout from 'src/pages/layouts/Layout';

export const Router = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Layout />}>
          <Route index element={<Compare />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          {/* Other routes */}
          <Route path="*" element={<ErrorPage/>} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />
}