import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { Router } from 'src/router/Router'; 
import { theme } from './styles/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CookieConsent from './components/cookieConsent/CookieConsent';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Notifications />
        <CookieConsent />
        <Router/>
      </MantineProvider>
    </QueryClientProvider>
  );
};
  
export default App; 