export const propertyTypes = [
  { value: 'house', label: 'Villa' },
  { value: 'apartment', label: 'Bostadsrätt' },
  { value: 'cottage', label: 'Fritidshus' },
];

export const energyClasses = [
  { value:'A', label: 'A' },
  { value:'B', label: 'B' },
  { value:'C', label: 'C' },
  { value:'D', label: 'D' },
  { value:'E', label: 'E' },
  { value:'F', label: 'F' }, 
  { value:'G', label: 'G' },
  { value:'Unknown', label: 'Vet ej' },
];

export const discounts: DiscountRequest[] = [
  {
    title: 'Skandia tjänstepension?',
    description: 'Extra rabatt -0.10%',
    bankId: 'E7162373-06E1-4F87-B4E7-8A8AA1B0F6BE',
    hasValue: false,
    value: 0,
    isDiscountApplied: false,
  },
  {
    title: 'Saco- eller TCO-fackmedlem?',
    description: 'Extra rabatt -0.10%',
    bankId: '1E997607-BE42-4BF0-B5A1-4E53CF6C6993',
    hasValue: false,
    value: 0,
    isDiscountApplied: false,
  },
  {
    title: 'IKEA Family medlem?',
    description: 'Extra rabatt -0.05%',
    bankId: 'E6A26C96-1700-4BCD-B4F8-D900A82DE1C3',
    hasValue: false,
    value: 0,
    isDiscountApplied: false,
  },
  {
    title: 'ICA Stammis medlem?',
    description: 'Extra rabatt upp till -0.50%',
    bankId: '9A676386-5709-40FB-B32E-AABEE7BA21CE',
    hasValue: true,
    value: 0,
    isDiscountApplied: false,
  },
  {
    title: 'Är du medlem i Villaägarna?',
    description: 'Extra rabatt upp till -1.42%',
    bankId: '9e32f7a1-95b2-4928-99a8-b135a992a93f',
    hasValue: false,
    value: 0,
    isDiscountApplied: false,
  },
];
