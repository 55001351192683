import React, { useState } from 'react';
import { Stack, Button, Group, Text, Center } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import classes from './ExpandableContent.module.css';

interface ExpandableContentProps {
  content: Array<{ label: string; value: string, unit: string }>;
}

export const ExpandableContent: React.FC<ExpandableContentProps> = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <Center>
        <Button
          onClick={toggleContent}
          variant="subtle"
          color="dark"
          rightSection={
            isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )
          }
          className={classes.toggleButton}
          aria-expanded={isOpen}
          aria-label={isOpen ? 'Dölj mer information' : 'Läs mer information'}
        >
          Läs mer
        </Button>
      </Center>

      <div
        style={{
          maxHeight: isOpen ? '600px' : '0',
          width: '100%',
          opacity: isOpen ? 1 : 0,
          overflow: 'hidden',
          transition: 'max-height 0.5s ease, opacity 0.5s ease',
        }}
      >
        <Stack mt="md">
          {content.map((item, index) => (
            <Group justify="space-between" key={index} className={classes.groupWithBorder}>
              <Text>{item.label}</Text>
              <Text className={classes.textValue}>{item.value}<span className={classes.textUnit}>{item.unit}</span></Text>
            </Group>
          ))}
        </Stack>
      </div>
    </>
  );
};