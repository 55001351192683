export function getExpandableContent(mortgage: CompareResponse) {
  return [
    { label: 'Listränta', value: `${mortgage.baseRate.toFixed(2)}`, unit: '%'},
    { label: 'Rabatt', value: `${mortgage.discount.toFixed(2)}`, unit: '%' },
    { label: 'Din ränta', value: `${mortgage.rate.toFixed(2)}`, unit: '%' },
    { label: 'Effektiv ränta', value: `${mortgage.effectiveRate.toFixed(2)}`, unit: '%' },
    { label: 'Räntekostnad', value: `${Number(mortgage.interestCost.toFixed(0)).toLocaleString('sv-SE')} `, unit: 'kr/mån' },
    { label: `Amortering (${mortgage.amortizationPercentage.toFixed(0)}%)`, value:  `${Number(mortgage.amortizationCost.toFixed(0)).toLocaleString('sv-SE')} `, unit: 'kr/mån' },
    { label: 'Total månadskostnad', value: `${Number(mortgage.totalCost.toFixed(0)).toLocaleString('sv-SE')} `, unit: 'kr/mån' },
    { label: 'Skatteavdrag', value: `${Number(mortgage.taxReduction.toFixed(0)).toLocaleString('sv-SE')} `, unit: 'kr/mån' },
    { label: 'Kostnad efter skatteavdrag', value: `${Number(mortgage.totalCostTaxReduced.toFixed(0)).toLocaleString('sv-SE')} `, unit: 'kr/mån' },
    { label: 'Belåningsgrad', value: `${mortgage.ltv.toFixed(0)}`, unit: '%' },
  ];
}