import { Container, Title, Flex, Group } from '@mantine/core';
import classes from './Hero.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export function Hero() {
  return (
    <div className={classes.hero}>
      
      <Container className={classes.container} size="1200px">
        <Title c="#fff">Jämför bolån och hitta din bästa ränta!</Title>
        <Title c="#fff" mt="lg" order={2}>
          Seranta är Sveriges enda tjänst där du kan jämföra personliga bolåneräntor. 
          Vi samlar och visar din bästa ränta från upp till 9 bolånegivare direkt – 
          helt kostnadsfritt och utan bindning!
        </Title>
        <Flex className={classes.flex} justify='space-between'>
          <Group className={classes.group} wrap='nowrap'>
            <Title c="#fff" order={2} ><FontAwesomeIcon icon={faCheck} /> Ingen kreditupplysning (UC) </Title>
          </Group>
          <Group className={classes.group} wrap='nowrap'>
            <Title c="#fff" order={2} ><FontAwesomeIcon icon={faCheck} /> Spara pengar med lägre ränta</Title>
          </Group>
          <Group className={classes.group} wrap='nowrap'>
            <Title c="#fff" order={2} ><FontAwesomeIcon icon={faCheck} /> Resultat visas direkt</Title>
          </Group>
       </Flex>
      </Container>
    </div>
  );

}