import React, { useState } from "react";
import { Button, Card, Center, Group, Skeleton, Stack, Text } from "@mantine/core";
import { motion } from "framer-motion";
import { interestTerms } from "./types";
import { Selection } from '../selection/Selection';
import SubscriptionCard from "./subscriptionCard/SubscriptionCard";
import { DeclinedCard } from "./declinedCard/DeclinedCard";
import { BankCard } from "./bankCard/BankCard";
import classes from './CompareResult.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface CompareResultProps {
  handleCompare: (interestTerm: string) => void;
  handleEmailSubscription: (email: string, token: string) => void;
  handleApplyButtonClick: (bankName: string, bankUrl: string) => void;
  selectedTerm: string;
  setSelectedTerm: React.Dispatch<React.SetStateAction<string>>;
  banks: Bank[] | undefined
  mortgages: CompareResponse[] | undefined,
}

export function CompareResult({ 
  handleCompare,
  handleEmailSubscription, 
  handleApplyButtonClick,
  selectedTerm,
  setSelectedTerm,
  banks,
  mortgages,
  }: CompareResultProps) {
    const [showAll, setShowAll] = useState(false);
    const initialDisplayCount = 5;

  const getMortgageByBank = (bankId: string | null) => {
    const mortgageList = Array.isArray(mortgages) ? mortgages : [];
    return mortgageList?.find((mortgage) => mortgage.bankId === bankId);
  };

  const sortedBanks = banks?.slice().sort((a, b) => {
    const mortgageA = getMortgageByBank(a.objectId);
    const mortgageB = getMortgageByBank(b.objectId);

    if (!mortgageA && mortgageB) return 1;
    if (mortgageA && !mortgageB) return -1;
    if (!mortgageA && !mortgageB) return 0;

    const hasDeclinedMessagesA = (mortgageA?.declinedMessages?.length ?? 0) > 0;
    const hasDeclinedMessagesB = (mortgageB?.declinedMessages?.length ?? 0) > 0;

    if (hasDeclinedMessagesA !== hasDeclinedMessagesB) {
        return hasDeclinedMessagesA ? 1 : -1;
    }

    if (hasDeclinedMessagesA && hasDeclinedMessagesB) {
        if (mortgageA!.averageRate == null) return 1;
        if (mortgageB!.averageRate == null) return -1;
        return mortgageA!.averageRate - mortgageB!.averageRate;
    }

    if (mortgageA && mortgageB) {
        if (mortgageA.rate == null) return 1;
        if (mortgageB.rate == null) return -1;
        return mortgageA.rate - mortgageB.rate;
    }

    return 0;
  });

  const excludedTerms = ['FourYear', 'SixYear', 'SevenYear', 'EightYear', 'NineYear'];

  const filteredInterestTerms = interestTerms
    .filter(term => !excludedTerms.includes(term.value))
    .map(term => ({ value: term.value, label: term.label }));

  const handleTermChange = (value: string) => {
    setSelectedTerm(value);
    handleCompare(value);
  };

  const toggleShowAll = () => setShowAll((prev) => !prev);
  
  return (
    <>
      <Stack className={classes.stack}>
        <Selection 
          selectedValue={selectedTerm}
          onChange={handleTermChange}
          data={filteredInterestTerms}
          size="sm"
          mt={0}
          ariaLabel="Bindningstid"
          label=""
        />
        {sortedBanks?.slice(0, showAll ? sortedBanks.length : initialDisplayCount).map((bank, index) => {
          const mortgage = getMortgageByBank(bank.objectId);
          
          if (!mortgage) {
            return (
              <motion.div key={bank.objectId} layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Card withBorder p="md" shadow="sm" mt="md" radius="md" h="200px" className={classes.card}>
                  <Group justify="space-between" p="sm">
                    <Stack>
                      <div
                        dangerouslySetInnerHTML={{ __html: bank.image }}
                        className={classes.bankImage}
                        aria-label={`Logo för ${bank.name}`}
                      />
                      <Skeleton height={20} width="80px" radius="md" />
                    </Stack>
                    <Stack>
                      <Skeleton height={40} width="100px" radius="md" />
                      <Skeleton height={20} width="80px" radius="md" />
                    </Stack>
                    <Stack>
                      <Skeleton height={40} width="60px" radius="md" />
                      <Skeleton height={20} width="50px" radius="md" />
                    </Stack>
                  </Group>
                </Card>
              </motion.div>
            );
          }

          if (mortgage.declinedMessages.length > 0) {
            return (
              <DeclinedCard
                key={bank.objectId}
                bank={bank}
                mortgage={mortgage}
                aria-label={`${bank.name} kan erbjudande något lån`}
              />
            );
          }

          // Regular bank card rendering logic
          if (index === 2) {
            return (
              <React.Fragment key={`fragment-${bank.objectId}`}>
                <BankCard
                  key={bank.objectId}
                  bank={bank}
                  mortgage={mortgage}
                  aria-label={`Bolånedetaljer för ${bank.name}`}
                  handleApplyButtonClick={handleApplyButtonClick}
                />
                <SubscriptionCard
                    handleEmailSubscription={handleEmailSubscription}
                    aria-label="Räntebevakning"
                  />
              </React.Fragment>
            );
          }
  
          return (
            <BankCard
              key={bank.objectId}
              bank={bank}
              mortgage={mortgage}
              handleApplyButtonClick={handleApplyButtonClick}
            />
          );
        })}
        {sortedBanks && sortedBanks.length > initialDisplayCount && (
          <Center>
            <Button
              onClick={toggleShowAll}
              variant="filled"
              size="md"
              color="dark"
              radius={30}
              className={classes.showMoreButton}
              aria-label={showAll ? 'Visa färre banker' : 'Visa fler banker'}
              rightSection={
                showAll ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )
              }
            >
              <Text c="#fff" fw="500">
                {showAll ? 'Visa färre banker' : 'Visa fler banker'}
              </Text>
              
            </Button>
          </Center>
        )}
      </Stack>
    </>
  );
}