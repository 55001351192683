export const CompareRequestEncoderDecoder = {
  encodeToBase64: (compareRequest: CompareRequest): string => {
    const encodedData = {
      propertyType: compareRequest.propertyType,
      propertyValuation: compareRequest.propertyValuation,
      loanAmount: compareRequest.loanAmount,
      interestTerm: compareRequest.interestTerm,
      energyClass: compareRequest.energyClass,
      bankSpecificDiscounts: compareRequest.bankSpecificDiscounts,
    };

    const jsonString = JSON.stringify(encodedData);
    const base64String = btoa(jsonString);

    return base64String;
  },

  decodeFromBase64: (base64String: string): CompareRequest => {
    const jsonString = atob(base64String);

    const decodedData = JSON.parse(jsonString);

    const decodedCompareRequest: CompareRequest = {
      propertyType: decodedData.propertyType,
      propertyValuation: decodedData.propertyValuation,
      loanAmount: decodedData.loanAmount,
      interestTerm: decodedData.interestTerm,
      energyClass: decodedData.energyClass,
      requestType: "Cookie",
      bankSpecificDiscounts: decodedData.bankSpecificDiscounts.map((discount: any) => ({
        bankId: discount.bankId,
        isDiscountApplied: discount.isDiscountApplied,
        value: discount.value,
        hasValue: discount.hasValue,
        description: discount.description,
        title: discount.title
      })),
    };

    return decodedCompareRequest;
  }
};