import React from "react";
import { Outlet } from "react-router-dom";
//import { HeaderMenu } from "src/components/header/HeaderMenu";
import { Footer } from "src/components/footer/Footer";

const RootLayout: React.FC = () => {
    return (
      <>
        <Outlet />
        <Footer/>
      </>
    )
}

export default RootLayout