import axios from 'axios';
import apiUrl from './api-url';

const apiClient = axios.create({
  baseURL: apiUrl, 
});

async function getGoogleAnalyticsClientId(): Promise<string | null> {
  return new Promise((resolve) => {
    if (window.gtag) {
      window.gtag('get', 'G-CMXTQR1Z8T', 'client_id', (clientId: string) => {
        resolve(clientId);
      });
    } else {
      console.warn("Google Analytics (gtag) not loaded");
      resolve(null);
    }
  });
}

apiClient.interceptors.request.use(async (config) => {
  const sessionId = await getGoogleAnalyticsClientId();
  if (sessionId) {
    config.headers['sessionId'] = sessionId;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default apiClient;