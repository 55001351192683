import { MantineSize, MantineSpacing, SegmentedControl, StyleProp, Text } from '@mantine/core';

interface SelectionProps {
  selectedValue: string;
  onChange: (value: string) => void;
  data: { value: string; label: string }[];
  size: MantineSize | (string & {}) | undefined;
  mt: StyleProp<MantineSpacing> | undefined;
  ariaLabel: string;
  label: string;
  mtLabel?: StyleProp<MantineSpacing> | undefined;
}

export function Selection({ selectedValue, onChange, data, size, mt, ariaLabel, label, mtLabel }: SelectionProps) {
    return (
      <>
        {label && <Text mt={mtLabel} fw="500">{label}</Text>}
        <SegmentedControl
          mt={mt}
          color="dark"
          radius="md"
          size={size}
          value={selectedValue}
          onChange={onChange}
          data={data}
          withItemsBorders={false}
          styles={{root: {
              boxShadow: "none"}
          }}
          aria-label={ariaLabel}
        />
      </>
    );
  }