export const interestTerms = [
  { value: 'ThreeMonth', label: '3 mån' },
  { value: 'OneYear', label: '1 år' },
  { value: 'TwoYear', label: '2 år' },
  { value: 'ThreeYear', label: '3 år' },
  { value: 'FourYear', label: '4 år' },
  { value: 'FiveYear', label: '5 år' },
  { value: 'SixYear', label: '6 år' },
  { value: 'SevenYear', label: '7 år' },
  { value: 'EightYear', label: '8 år' },
  { value: 'NineYear', label: '9 år' },
  { value: 'TenYear', label: '10 år' },
];