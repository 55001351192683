import React from 'react';
import { Card, Grid, Box, Text } from '@mantine/core';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { ExpandableContent } from '../expandableContent/ExpandableContent';
import classes from './BankCard.module.css';
import { getExpandableContent } from '../expandableContent';
import MortgageApplyButton from './MortgagesApplyButton';

interface BankCardProps {
  handleApplyButtonClick: (bankName: string, bankUrl: string) => void;
  bank: Bank;
  mortgage: CompareResponse;
}

export const BankCard: React.FC<BankCardProps> = ({handleApplyButtonClick, bank, mortgage }) => {

  const expandableContent = getExpandableContent(mortgage);

  return (
    <motion.div key={bank.objectId} layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Card withBorder className={classes.card}>
        <Grid gutter="md" justify="space-between" align="center">
          <Grid.Col span={3} className={classes.gridColLeft}>
            <div
              dangerouslySetInnerHTML={{ __html: bank.image }}
              className={classes.bankImage}
            />
          </Grid.Col>
          <Grid.Col span={6} className={classes.gridColCenter}>
            <Box className={classes.rate}>
              <Text className={classes.rateText}>
                {mortgage.rate.toFixed(2)}%
              </Text>
            </Box>
          </Grid.Col>
          <Grid.Col span={3} className={classes.gridColRight}>
            <MortgageApplyButton 
              mortgage={{
                bankUrl: mortgage.bankUrl!,
                bankName: bank.name
              }}
              handleApplyButtonClick={handleApplyButtonClick}
            ></MortgageApplyButton>
          </Grid.Col>
        </Grid>

        <Grid gutter="md" justify="space-between" align="center" mt="md">
          <Grid.Col span={3} className={classes.gridColSmallLeft}>
            <Text className={classes.text}>
              {mortgage.averageRate.toFixed(2)}% 
              <small className={classes.smallText}> Snittränta</small>
            </Text>
          </Grid.Col>
          <Grid.Col span={6} className={classes.gridColSmall}>
            <Text className={classes.text}>
              {Number(mortgage.totalCost.toFixed(0)).toLocaleString('sv-SE')}
              <small className={classes.smallText}> kr/mån</small>
            </Text>
          </Grid.Col>
          <Grid.Col span={3} className={classes.gridColSmallRight}>
            {mortgage.isGreenLoan ? (
              <Text c="#48752C" fw="600">
                <FontAwesomeIcon className={classes.greenLoanIcon} icon={faLeaf} /> Grönt bolån
              </Text>
            ) : (
              <Text c="#C7C7C7" fw="600">
                <FontAwesomeIcon className={classes.greenLoanIcon} icon={faLeaf} /> Grönt bolån
              </Text>
            )}
          </Grid.Col>
        </Grid>

        {/* Use ExpandableContent component */}
        <ExpandableContent content={expandableContent} />
      </Card>
    </motion.div>
  );
};