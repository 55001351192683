import { Container, Title, Text } from '@mantine/core';
import { Illustration } from './Illustration';
import classes from './ErrorPage.module.css';

export default function Contact() {
  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <Illustration className={classes.image} />
        <div className={classes.content}>
          <Title className={classes.title}>Sidan finns inte</Title>
          <Text c="dimmed" size="lg" ta="left" className={classes.description}>
            Sidan ni försökte öppna existerar inte. 
            Eller så har den flyttats till en annan adress. 
          </Text>
        </div>
      </div>
    </Container>
  );
}

