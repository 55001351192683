import { createTheme, rem, DEFAULT_THEME, mergeMantineTheme,} from '@mantine/core';

const themeOverride = createTheme({
  colors: {
    // Add your color
    deepBlue: [
      '#eef3ff',
      '#dce4f5',
      '#b9c7e2',
      '#94a8d0',
      '#748dc1',
      '#5f7cb8',
      '#5474b4',
      '#44639f',
      '#39588f',
      '#2d4b81',
    ],
  },

  defaultRadius: "sm",
  headings: {
    fontFamily: 'Roboto, sans-serif',
    sizes: {
      h1: { fontSize: rem(36) },
    },
  },
});

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);
