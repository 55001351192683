import { useMutation, useQuery } from '@tanstack/react-query';
import apiClient from 'src/api/apiClient';

export const useMortgageData = (request: CompareRequest, enabled: boolean) => {
  return useQuery<CompareResponse[], Error>({
    queryKey: ['mortgages', request],
    queryFn: async (): Promise<CompareResponse[]> => {
      const response = await apiClient.post('/api/v1/mortgages', request);
      return response.data;
    },
    enabled,
    refetchOnWindowFocus: false,
  });
};

export const useBanks = () => {
  return useQuery<Bank[], Error>({
    queryKey: ['banks'],
    queryFn: async (): Promise<Bank[]> => {
      const response = await apiClient.get('api/v1/banking/banks');
      return response.data;
    },
    refetchOnWindowFocus: false,
  });
};

export const useAddEmailSubscription = () => {
  return useMutation<EmailSubscriptionRequest, Error, EmailSubscriptionRequest>({
    mutationFn: async (emailSubscription: EmailSubscriptionRequest): Promise<EmailSubscriptionRequest> => {
      const response = await apiClient.post('api/v1/subscription', emailSubscription);
      return response.data;
    }
  });
};

export const usePublishApplyInfo = () => {
  return useMutation<PublishApplyInfoRequest, Error, PublishApplyInfoRequest>({
    mutationFn: async (publishApplyInfo: PublishApplyInfoRequest): Promise<PublishApplyInfoRequest> => {
      const response = await apiClient.post('api/internal/publish-apply-info', publishApplyInfo);
      return response.data;
    }
  });
};