import { Container, Paper, Title, Text, List } from "@mantine/core";

export const CookiePolicy = () => {

  return (
    <Container size="sm" mt="xl">
      <Paper withBorder shadow="md" p="lg" radius="md">
        <Title order={1} mb="md">
          Cookie Policy
        </Title>
        
        <Text mb="md">
          Vi använder cookies för att förbättra din upplevelse på vår webbplats. I denna policy
          förklarar vi vilka typer av cookies vi använder, hur vi använder dem, och hur du kan
          kontrollera dem.
        </Text>

        <Title order={2} mt="md" mb="sm">
          Vad är cookies?
        </Title>
        <Text mb="md">
          Cookies är små textfiler som sparas på din enhet (dator, mobil eller surfplatta) när du
          besöker vår webbplats. De används för att lagra information om ditt besök, vilket gör att
          vi kan förbättra webbplatsen och ge dig en bättre upplevelse.
        </Text>

        <Title order={2} mt="md" mb="sm">
          Typer av cookies vi använder
        </Title>
        <List>
          <List.Item>
            <strong>Nödvändiga cookies:</strong> Dessa cookies är avgörande för att webbplatsen ska
            fungera korrekt. De kan inte inaktiveras i våra system.
          </List.Item>
          <List.Item>
            <strong>Funktionella cookies:</strong> Dessa cookies gör det möjligt för oss att anpassa
            webbplatsen efter dina val, som ditt språk eller region.
          </List.Item>
          <List.Item>
            <strong>Analyscookies:</strong> Vi använder Google Analytics för att samla in anonym
            information om hur besökare använder vår webbplats. Dessa cookies hjälper oss att
            förbättra webbplatsens prestanda.
          </List.Item>
          <List.Item>
            <strong>Marknadsföringscookies:</strong> Dessa cookies används för att spåra besökare
            över olika webbplatser och ge anpassade annonser.
          </List.Item>
        </List>

        <Title order={2} mt="md" mb="sm">
          Hur kan du hantera cookies?
        </Title>
        <Text mb="md">
          Du kan när som helst ändra dina cookie-inställningar genom din webbläsare. De flesta
          webbläsare låter dig blockera eller radera cookies. Observera att om du blockerar cookies
          kan vissa delar av vår webbplats sluta fungera korrekt.
        </Text>

        <Text mb="md">
          För mer information om hur du hanterar cookies kan du besöka följande länkar beroende på
          vilken webbläsare du använder:
        </Text>
        <List>
          <List.Item>
            <a href="https://support.google.com/chrome/answer/95647?hl=sv" target="_blank" rel="noopener noreferrer">
              Google Chrome
            </a>
          </List.Item>
          <List.Item>
            <a href="https://support.mozilla.org/sv/kb/aktivera-och-inaktivera-cookies-som-webbplatser-anv" target="_blank" rel="noopener noreferrer">
              Mozilla Firefox
            </a>
          </List.Item>
          <List.Item>
            <a href="https://support.microsoft.com/sv-se/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank" rel="noopener noreferrer">
              Microsoft Edge
            </a>
          </List.Item>
          <List.Item>
            <a href="https://support.apple.com/sv-se/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">
              Safari
            </a>
          </List.Item>
        </List>

        <Title order={2} mt="md" mb="sm">
          Ändringar i vår cookie-policy
        </Title>
        <Text>
          Vi kan uppdatera denna cookiepolicy från tid till annan för att reflektera ändringar i vår
          användning av cookies. Alla ändringar kommer att publiceras på denna sida.
        </Text>
      </Paper>
    </Container>
  );
};

export default CookiePolicy;