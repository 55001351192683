import Cookies from 'js-cookie';
import { CompareRequestEncoderDecoder } from './CompareRequestEncoderDecoder';

export const initializeRequest = (
  cookieName: string,
  encodedContent: string | null,
  requestTypes: any[], // Replace `any` with the correct type if available
  discounts: DiscountRequest[],
  defaultValues = {
    propertyValuation: 5000000,
    loanAmount: 2600000,
    propertyType: 'house',
    energyClass: 'Unknown',
    interestTerm: 'ThreeMonth',
    requestType: requestTypes[0]?.value,
  }
): { initialRequest: CompareRequest; initialDiscountsState: DiscountRequest[] } => {
  let initialRequest: CompareRequest = {
    propertyValuation: defaultValues.propertyValuation,
    loanAmount: defaultValues.loanAmount,
    propertyType: defaultValues.propertyType,
    energyClass: defaultValues.energyClass,
    interestTerm: defaultValues.interestTerm,
    requestType: defaultValues.requestType,
    bankSpecificDiscounts: discounts, // Directly assign the discounts array
  };

  // Attempt to decode content from URL or cookie
  if (encodedContent) {
    try {
      const decodedRequest = CompareRequestEncoderDecoder.decodeFromBase64(encodedContent);
      initialRequest = { ...decodedRequest, requestType: requestTypes[3]?.value };
    } catch (error) {
      console.error("Failed to decode 'content' parameter:", error);
    }
  } else {
    const savedRequest = Cookies.get(cookieName);
    if (savedRequest) {
      try {
        const decodedRequest = CompareRequestEncoderDecoder.decodeFromBase64(savedRequest);
        initialRequest = { ...decodedRequest, requestType: requestTypes[2]?.value };
      } catch (error) {
        console.error('Failed to decode saved request from cookie:', error);
      }
    }
  }

  // Initialize discounts state with metadata from default discounts
  const initialDiscountsState: DiscountRequest[] = discounts.map((defaultDiscount) => {
    const existingDiscount = initialRequest.bankSpecificDiscounts.find(
      (discount: DiscountRequest) => discount.bankId === defaultDiscount.bankId
    );

    return {
      ...defaultDiscount,
      isDiscountApplied: existingDiscount?.isDiscountApplied || defaultDiscount.isDiscountApplied,
      value: existingDiscount?.value ?? defaultDiscount.value,
      title: defaultDiscount.title,
      description: defaultDiscount.description,
      hasValue: defaultDiscount.hasValue
    };
  });

  return { initialRequest, initialDiscountsState };
};