import React, { useState } from 'react';
import { Card, Text, TextInput, Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Turnstile } from '@marsidev/react-turnstile';
import classes from './SubscriptionCard.module.css';

interface SubscriptionCardProps {
  handleEmailSubscription: (email: string, token: string) => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ handleEmailSubscription }) => {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+\.\S+$/.test(value) ? null : 'Felaktig epost adress'),
    },
  });

  const handleSubmit = (values: { email: string }) => {
    if (captchaToken) {
      handleEmailSubscription(values.email.toLowerCase(), captchaToken);
      form.reset();
      setCaptchaToken(null);
    }
  };

  const handleCaptchaSuccess = (token: string) => {
    setCaptchaToken(token);
  };

  return (
    <Card className={classes.card} withBorder>
      <Text size="32px" c="#fff" fw="500">Skapa räntebevakning</Text>
      <Text size="20px" c="#fff">
        Bolånegivarnas räntor ändras över tid.
        Med vår räntebevakning får du månatliga
        uppdateringar om de bästa bolåneräntorna just för dig!
      </Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group mt="24px" justify="flex-start">
          <TextInput
            w="378px"
            withAsterisk={false}
            placeholder="Ange din mailadress"
            size="md"
            radius="md"
            {...form.getInputProps('email')}
            aria-label="Ange din e-postadress"
            styles={{
              input: {
                borderColor: 'white',
              },
            }}
          />
          <Button
            size="md"
            variant="filled"
            color="dark"
            radius={30}
            type="submit"
            aria-label="Spara din e-post för att få räntebevakningar"
          >
            Få löpande uppdateringar
          </Button>
        </Group>
      </form>
      <Text size="10px" mt="8px" c="#fff" lts="0">
        Genom att registrera dig får du automatiska uppdateringar om
        de senaste bolåneräntorna varje månad, baserat på dina valda
        värden vid registrering. Du kan när som helst ändra dina värden
        eller avsluta prenumerationen.
      </Text>
      <label>
        <span className={classes.captchaLabel}>CAPTCHA verifiering för att skicka e-post</span>
        <Turnstile
          className={classes.turnStile}
          siteKey="0x4AAAAAAAyIZjEWnP32Md08"
          onSuccess={handleCaptchaSuccess}
          options={{
            action: 'submit-form',
            theme: 'dark',
            language: 'sv',
            appearance: "interaction-only",
            size: "normal"
          }}
        />
      </label>
    </Card>
  );
};

export default React.memo(SubscriptionCard);